<template>
  <div class="container">
    <h4 class="blue-text font-weight-bold">プロフィール編集</h4>
    <form v-on:submit="updateUserProfile">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-6">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">名前（姓）</p>
                <input
                  id="first-name"
                  class="form-control shadow-1"
                  type="text"
                  minlength="1"
                  maxlength="100"
                  v-model="lastName"
                  v-on:keyup="formMessage = null"
                  placeholder="名前（姓）を入力してください"
                  required
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">名前 (名)</p>
                <input
                  id="last-name"
                  class="form-control shadow-1"
                  type="text"
                  minlength="1"
                  maxlength="100"
                  v-model="firstName"
                  v-on:keyup="formMessage = null"
                  placeholder="名前（名）を入力してください"
                  required
                />
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">法人名</p>
                <input
                  id="last-name"
                  class="form-control shadow-1"
                  type="text"
                  v-model="companyName"
                  placeholder="法人名を入力してください"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">生年月日</p>
                <v-date-picker
                  v-model="birthDate"
                  :masks="{ L: 'YYYY-MM-DD' }"
                  locale="ja"
                  mode="date"
                  :max-date="new Date()"
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div class="date-picker-wrap">
                      <input
                        :value="inputValue"
                        v-on:click="togglePopover"
                        class="form-control datepicker-input shadow-1"
                        placeholder="YYYY-MM-DD"
                        required
                      />
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">性別</p>
                <b-form-select
                  class="shadow-1"
                  v-model="gender"
                  :options="getGender()"
                  required
                ></b-form-select>
              </div>
            </div>
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">
                  <span>都道府県</span>
                  <b-spinner small v-if="prefectures.loader"></b-spinner>
                </p>
                <b-form-select
                  class="shadow-1"
                  v-model="prefectures.value"
                  :options="prefectures.options"
                  required
                ></b-form-select>
              </div>
            </div>
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">
                  <span>アカウントのタイプ</span>
                </p>
                <b-form-group>
                  <b-form-radio-group v-model="accountType" name="account-type">
                    <b-form-radio value="1">法人</b-form-radio>
                    <b-form-radio value="2">個人</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-6">
          <div class="row">
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 font-weight-bold">属性</p>
                <b-form-select
                  class="shadow-1"
                  v-model="client_attributes"
                  :options="getClientAttributes()"
                  required
                ></b-form-select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <b-alert
            class="m-0 mt-3 light-grey-outline grey-text"
            variant="warning"
            show
            v-if="formMessage !== null"
          >
            {{ formMessage }}
          </b-alert>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-3 mt-5">
          <router-link
            :to="{
              name: 'userRoute-dashboard',
            }"
          >
            <button class="btn btn-ds grey white-text">キャンセル</button>
          </router-link>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-3 mt-5">
          <button
            class="btn btn-ds dark-blue white-text"
            type="submit"
            v-waves.light
          >
            更新
          </button>
        </div>
      </div>
    </form>

    <b-modal
      id="user-profile-update-success-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="$bvModal.hide('user-profile-update-success-modal')"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          プロフィールが更新されました
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="$bvModal.hide('user-profile-update-success-modal')"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Services
import { globalService_GetAllPrefectures } from '../../services/global';
import {
  cus_userService_UpdateUserProfile,
  cus_userService_UpdateUserQuestionnaire,
} from '../../services/customer/users';

// Import Moment JS
import moment from 'moment';

// Import Vue Calendar
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

//import common
import {
  getGender,
  getClientAttributes,
  getFloorPlanTypes,
  getBuildingType,
  getManagedPropertyNumbers,
} from '../../common/Utility';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Profile | User Info',
    };
  },

  components: {
    'v-date-picker': DatePicker,
  },

  data() {
    return {
      firstName: null,
      lastName: null,
      companyName: null,
      birthDate: null,
      client_attributes: null,
      floor_plan_type: null,
      building_type: null,
      managed_property_numbers: null,
      gender: null,
      prefectures: {
        value: null,
        options: [],
        loader: true,
      },
      accountType: 1,
      formMessage: null,
    };
  },

  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn']),
  },

  mounted() {
    this.setUserProfile();
    this.retievePrefectures();
  },

  methods: {
    getGender,
    getClientAttributes,
    getFloorPlanTypes,
    getBuildingType,
    getManagedPropertyNumbers,
    setUserProfile() {
      console.log('currentUser', this.currentUser);
      if (this.currentUser.user_profile) {
        this.firstName = this.currentUser.user_profile.first_name;
        this.lastName = this.currentUser.user_profile.last_name;
        this.companyName = this.currentUser.user_profile.company_name;
        this.birthDate = this.currentUser.user_profile.birth_date;
        this.gender = this.currentUser.user_profile.gender;
        this.prefectures.value = this.currentUser.user_profile.prefectures;
      }
      if (this.currentUser.questionnaire) {
        this.client_attributes =
          this.currentUser.questionnaire.client_attributes;
        this.floor_plan_type = this.currentUser.questionnaire.floor_plan_type;
        this.building_type = this.currentUser.questionnaire.building_type;
        this.managed_property_numbers =
          this.currentUser.questionnaire.managed_property_numbers;
      }
    },

    retievePrefectures() {
      let _this = this;

      _this.prefectures.loader = true;

      globalService_GetAllPrefectures()
        .then((response) => {
          let data = response.data;

          let prefectures = [];
          prefectures.push({
            value: null,
            text: '都道府県を選択してください',
            disabled: true,
          });

          data.forEach((el) => {
            prefectures.push({
              value: el.id,
              text: el.name,
            });
          });

          _this.prefectures.options = prefectures;

          _this.prefectures.loader = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },

    async updateUserProfile(e) {
      let _this = this;

      e.preventDefault();

      _this.formMessage = null;

      _this.$store.state.modalLoaderMessage = '保存中';
      _this.$bvModal.show('modal-loader');

      let accessToken = localStorage.getItem('_accessToken');

      let success = false;

      //Update Profile
      await cus_userService_UpdateUserProfile({
        id: _this.currentUser.id,
        first_name: _this.firstName,
        middle_name: null,
        last_name: _this.lastName,
        company_name: _this.companyName,
        birth_date: _this.formatDate(_this.birthDate),
        gender: _this.gender,
        prefectures: _this.prefectures.value,
        token: accessToken,
      })
        .then(() => {
          success = true;
        })
        .catch((error) => {
          console.log(error);
          success = false;
          _this.formMessage =
            'プロフィールの更新中にエラーが起こりました。再度お試しください。';
        });

      //Update Questionnaire
      await cus_userService_UpdateUserQuestionnaire({
        id: _this.currentUser.id,
        client_attributes: _this.client_attributes,
        floor_plan_type: _this.floor_plan_type,
        building_type: _this.building_type,
        managed_property_numbers: _this.managed_property_numbers,
        token: accessToken,
      })
        .then(() => {
          success = true;
        })
        .catch((error) => {
          console.log(error);
          success = false;
          _this.formMessage =
            'プロフィールの更新中にエラーが起こりました。再度お試しください。';
        });

      setTimeout(() => {
        _this.$bvModal.hide('modal-loader');
      }, 500);
      if (success) {
        setTimeout(() => {
          _this.$bvModal.show('user-profile-update-success-modal');
        }, 500);
      }
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom style */
</style>
